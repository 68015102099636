import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const transGourmet = 'goodbytz';
export const customerGroup = transGourmet;

const supportEmail = {
	goodbytz: 'goodbytz@smoothr.de'
};
const firebaseConfig = {
	goodbytz: {
		apiKey: 'AIzaSyDzRphheBb6PUySA_BeKRLmx_nym8cF9u4',
		authDomain: 'transgourmet-d715d.firebaseapp.com',
		projectId: 'transgourmet-d715d',
		storageBucket: 'transgourmet-d715d.appspot.com',
		messagingSenderId: '346931949800',
		appId: '1:346931949800:web:57b56009e5c8465591995d'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBv5IfnRegn5x6r9X6mSliaZfgx99jyI6U',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: true,
	showOrderPriceFromBackend: false,
	showDiffrenceModal: false,
	payPalValidatePrice: false,
	payPalMinPrice: 0,
	getPriceByUser: ''
};

import 'zone.js/dist/zone-error';
