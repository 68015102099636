import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectLanguageModalComponent} from '../select-language-modal/select-language-modal.component';
import {ModalController} from '@ionic/angular';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {SelectTableModalComponent} from '../select-table-modal/select-table-modal.component';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-header-with-language-switcher',
	templateUrl: './header-with-language-switcher.component.html',
	styleUrls: ['./header-with-language-switcher.component.scss']
})
export class HeaderWithLanguageSwitcherComponent implements OnInit {
	@Input() logo = false;
	@Input() backButton = false;
	@Input() burgerButton = false;
	@Input() crossButton = false;
	@Input() venue: Venue;
	@Input() order: Order;
	@Input() lang = false;
	@Input() table = false;
	@Input() title = '';

	@Output() backClick = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();

	pt = PreorderType;

	constructor(
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private repository: RepositoryService
	) {}

	ngOnInit() {}
	async selectLanguageModal() {
		await SelectLanguageModalComponent.show(this.modalCtrl);
	}
	async openSelectTableModal() {
		if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
			const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
			if (selectTable) {
				this.order.tableNumber = selectTable.table;
				this.order.table = selectTable.tableId;
				this.repository.order.emit(this.order);
			}
		}
	}
}
